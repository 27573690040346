.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 10px 20px 30px 20px;
    border-radius: 8px;
    text-align: right;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .popup-close-button {
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: black;
  }

  .shareWp {
    text-decoration: none;
    background-color: #03A684;
    border: 1px solid #03A684;
    padding: 0.4rem 0.4rem;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 13px auto 26px auto;
    gap: 10px;
    width: 12rem;
  }

  @media screen and (max-width:600px) {
    .popup-content{
      padding: 10px 20px 20px 20px;
      max-width: 90%;
    }

    .shareWp {
      margin: 7px auto 14px auto;
    }
  }