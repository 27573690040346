.album-selection {
    width: 90%;
    margin: auto;
    /* background: rgba(0, 0, 0, 0.4); */
    margin: 0px auto;


}

.card-overlay {
    display: none;
}
.album-sel-card {
    width: 48%;
    /* height:auto; */
}
.album-sel-card:hover .card-overlay{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex!important;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    gap: 9px;
    border-radius: 0.3rem;
}
.card-footer-al-sel {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    justify-content: space-evenly;
    padding: 10px 0;
    align-items: center;
}
.card-img-al-sel {
    position: relative;
    width: 100%;
    height: 25vw;
}
.card-img-al-sel img{
    width: 100%;
    height: 100%;
}
.card-img-al-sel-overlay{
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 2px 10px;
    color: white;
    background: rgb(0 0 0 / 29%);
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
}
.album-sel-cards{

    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}
.hollow-btn {
    border: 2px solid #319795;
    color: #319795;
    background: transparent;
    border-radius: 0.3rem;
    padding: 6px 10px;
    gap: 10px;
    display: flex;
    font-weight: 800;
    align-items: center;
}
@media screen  and (max-width: 699px){
    .album-sel-card {
        width: 100%;
        margin: 20px 0;
    }
    .card-img-al-sel{
        height: 28vh!important;
    }
    Box {
        font-size: 10px;
    }
}