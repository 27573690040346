.sidebar {
    display: flex;
    position: fixed;
    width: 20%;
    height: 100vh;
    right: 0;
    top: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 999;
    /* background-color: #e9e9e9; */
    background-color: #fbf7f4;

}

.sidebar-sub-heading {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left !important;
    display: flex;
    justify-content: flex-start;
}
.top-sidebar {
    position: relative;

    width: 100%;
}

.middle-sidebar {
    position: relative;
    margin-top: 4%;

    width: 100%;
}

.bottom-sidebar {
    position: relative;
    margin-top: 4%;

    width: 100%;
}

.sub-links-sidebar {
    background-color: #fbf7f4;

}

.close-icon-sidebar {

    position: absolute;
    top: 10px;
    left: 0;
    font-size: 54px;
    padding: 12px;
    z-index: 1;
}

.userDetails {
    display: flex;
    width: 100%;
    font-size: 24px;
    /* gap: 24px; */
    padding-top: 10px;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.userDetails li {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: center;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    padding: 10px 0;
}
.userDetails li p{
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 75%;
    font-size: 1.2rem;
    align-items: center;
    gap: 5%;
}
.sub-links-sidebar li:hover {
    background-color: #000000a9;
    color: white;
}
.sidebar-sub-heading:hover{
    background-color: #e9e9e9;

}
.sidebar-overlay{
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 699px) {
    .sidebar {
        width: 70%;
    }
    .userDetails li p {
        display: flex;
        justify-content: start;
        flex-direction: row;
        width: 85%;
        font-size: 1.2rem;
        align-items: center;
        gap: 5%;
    }
    .userDetails {

        font-size: 18px;

    }
}

@media only screen and (min-width:699px) and (max-width: 1300px) {
    .sidebar {
        width: 50%;
    }

    .userDetails {

        font-size: 21px;

    }

    .sidebar-sub-heading {
        font-size: 20px;
        color: #000000;
        font-weight: 600;
        text-transform: uppercase;
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 500px) {
    .sidebar-sub-heading {
        font-size: 18px;
        color: #000000;
        font-weight: 600;
        text-transform: uppercase;
        text-align: left !important;
        display: flex;
        justify-content: flex-start;
    }

    .userDetails li p {
        font-size: 0.8rem;
    }
}