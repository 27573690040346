* {
    user-select: none;
}

.uploadButton {
    color: white;
    margin-right:10px;
    padding: 5px 10px;
    border-radius: 10px;
    bottom: 10%;
    display: flex;
    align-items: center;
    right: 5%;
    z-index: 50;
}

.infoDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.downloadEffect {
    color: rgb(191, 191, 191);
    width: 1rem;
}

.closeEffect {
    color: rgb(191, 191, 191);
    width: 1rem;
}

.downloadEffect:hover {
    color: white;
}

.closeEffect:hover {
    color: white;
}

.guestBottomControls {
    position: absolute;
    top: 20px;
    right: 100px;
}

._t1897 {
    object-fit: contain !important;
    max-height: 90%;
}

.uploadPopup {
    background: white;
    padding: 10px 20px;
    display: inline;
    min-width: 40vh;
    border-radius: 10px;
    color: black;
}

.popupContent {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f0f0f0;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

._3WRGR {
    fill: white !important;
    width: 1rem;
}

.__JnHV {
    background: black !important;
    color: white;
}

.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

.masonry-item {
    margin-bottom: 30px;
}

.guestPhotoName {
    position: absolute;
    bottom: 5%;
    left: 5%;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1px 8px;
    border-radius: 5px;
}

@media screen and (max-width:1200px) {
    .uploadButton {
        background-color: white !important;
        color: black;
    }
}

@media screen and (max-width:1050px) {
    .guestPhotoName {
        font-size: 17px;
    }
}

@media screen and (max-width:850px) {
    .guestPhotoName {
        font-size: 15px;
    }

    ._t1897 {
        margin-top: 20px !important;
    }
}

@media screen and (max-width:600px) {
    .guestBottomControls {
        right: 5%;
        width: 90%;
        display: flex;
        justify-content: space-evenly;
        top: 80vh;
    }

    ._t1897 {
        margin-top: 45px !important;
    }
}