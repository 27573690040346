.video-js .vjs-control-bar {
    background-color: transparent !important;
    /* bottom: 15% !important; */
    z-index: 100;
}

.qualityBlock {
    padding-left: 10px;
}

.mainMenu {
    margin: 0 10px;
    padding: 8px 0;
    font-size: 13px;
    text-align: left;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.hdDiv {
    margin-left: 20px;
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 2px;
}

.qualityDiv {
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    width: max-content;
    height: 100%;
}

.currentTime {
    display: none;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.extraMenu {
    flex-direction: column;
    position: absolute;
    bottom: 35px;
    right: 35px;
    background: white;
    color: black;
    padding: 5px 10px;
    display: flex;
    border-radius: 5px;
    text-align: left;
    font-size: 13px;
}

.oneMenu {
    padding: 8px 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    min-width: 140px !important;
}

.qualitySelect {
    padding: 8px 10px !important;
    display: flex !important;
    justify-content: flex-start !important;
    min-width: 120px !important;
    font-size: 13px !important;
}

.rateSelect {
    padding: 8px 10px !important;
    display: flex !important;
    justify-content: flex-start !important;
    min-width: 110px !important;
    font-size: 13px !important;
}

.qualityMenu {
    display: flex;
    flex-direction: column-reverse;
    background-color: white;
    color: black;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-radius: 5px;
}

.rateMenu {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-radius: 5px;
}

.shareDiv {
    width: 4rem;
    cursor: pointer;
}

.logoEg {
    width: 5rem
}

.overlayMobile {
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width: 600px) {
    .shareDiv {
        padding-left: 2rem;
    }

    .logoEg {
        width: 4rem
    }

    .vjs-big-play-button {
        display: none !important;
    }

    .navbarVideo {
        padding: 4% 5% !important;
    }
}
