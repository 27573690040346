HTMLFlipBook {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgClass {
    position: absolute;
    left: 25vw !important;
}

