.error-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    overflow: hidden;
    width: 100vw;
    margin-top: 4rem;
}

.error-box{
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    text-align: center;
    width: 30rem;
    transform: translateY(-8rem);
    height: fit-content;
    margin-top: 5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);

    max-width: 90vw;
}

.error-title{
    font-size: 1.3rem !important;
    font-weight: 500;
    color: white;
}

.error-message{
    color: whitesmoke;
    font-size: 1.75rem;
    font-weight: bold;
}

.error-button{
    margin-top: 0.5rem;
    padding: 1rem 0.8rem;
    background-color: white;
    color: black;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 15px;

    display: flex;
    align-items: center;
    gap: 0.5rem;


    border: 1px solid rgba(0, 0, 0, 0.5);
}


.error-gif{
    width: 10rem;
    height: 10rem;
}

.error-gif img{
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 1200px) {
    .error-container{
        height: 90vh;
        margin-top: 3rem;
    }

    .error-box{
        padding: 1.5rem;
        width: 25rem;
    }


    .error-message{
        font-size: 1.6rem !important;
    }

    .error-button{
        padding: 0.8rem 0.6rem;
        font-size: 0.8rem;
    }

    .error-gif{
        width: 8rem;
        height: 8rem;
    }
}



@media only screen and (max-width: 600px) {
    .error-container{
        height: 90vh;
        margin-top: 4rem;
    }

    .error-box{
        padding: 1rem;
        width: 20rem;
    }


    .error-title{
        font-size: 1.2rem !important;
    }

    .error-message{
        color: whitesmoke;
        /* margin-top: 1rem;
        margin-bottom: 1rem !important; */
        font-size: 1.5rem !important;
    }

    .error-gif{
        width: 7rem;
        height: 7rem;
    }

}