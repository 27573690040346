* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  /* background: #fbf7f4; */
  --chakra-colors-white:#fbf7f4;
}
body{
background-color: #fbf7f4;
}
.chakra-card{
  width: 250px;
}
.visHid{
  visibility: hidden;
}
.tc{
  text-align: center;
}
.pointer{
  cursor: pointer;
}
.r8{
  margin-right: 8px;
}
.centerIt{
  display: flex;
  justify-content: center;
  align-items: center;
}
.filled-btn {
  background: #319795;
  color: white;
  border-radius: 0.3rem;
  padding: 10px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
}
.hollow-btn {
  border :2px solid #319795;
  color: #319795;
  background: transparent;
  border-radius: 0.3rem;
  padding: 10px 15px;
  gap: 10px;
  display: flex;
  align-items: center;
}
.hollow-btn:hover{
  background: #319795;
  color: white;
}
.icon {
  background: transparent;
  font-size: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.fullpage-overlay{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
}