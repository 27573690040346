.downloadBody {
    padding-top: 40px;
}

h3 {
    display: block;
    font-size: 1.17em !important;
    font-weight: bold !important;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
}

.downloadInst {
    background-color: white;
    padding: 10px 22px;
    border-radius: 10px;
    max-width: 70%;
}

.downloadInst h1 {
    font-weight: 900 !important;
    text-align: left;
    padding: 10px 0;
}

.downloadInst h3 {
    font-weight: 900 !important;
    font-size: 20px;
    padding: 15px 0;
}

.reDownloadDiv {
    background: white;
    max-width: 40%;
    border-radius: 10px;
    padding: 10px 22px;
    font-weight: 900;
}

.notSavingDiv {
    color: white;
    padding-top: 80px;
    padding-bottom: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
}

.reDownload {
    color: white;
    border-radius: 5px;
    padding: 5px 12px;
    white-space: nowrap;
}

.batch-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
    margin: auto;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress-bar-percent{
    text-align: right;
}

.batchDiv {
    padding: 1rem;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.progressDetail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progressLost {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}

.batch-info {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    max-width: 80%;
}

.progress-bar-container {
    width: 100%;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 8px;
}

.progress-bar {
    height: 100%;
    border-radius: 4px;
    transition: width 0.3s ease;
}

@media screen and (max-width:900px) {
    .downloadInst {
        max-width: 70%;
    }

    .reDownloadDiv {
        max-width: 70%;
    }
}

@media screen and (max-width:600px) {
    .downloadInst {
        max-width: 90%;
    }

    .reDownloadDiv {
        max-width: 95%;
        padding: 10px 8px;
    }
}