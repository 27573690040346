@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Petit+Formal+Script&family=Style+Script&display=swap');

@font-face {
    font-family: 'artecallya';
    src: url('../../fonts/artecallya/Artecallya-Script.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.aim-point {
    font-family: 'artecallya';
    font-size: 4rem;
    color: rgb(159, 120, 102);
}

.collapsed {
    position: relative;
    transition: height 0.5s ease-in-out;
    mask-image: linear-gradient(black 50%, transparent);
}

.name_title {
    font-family: 'artecallya';
    color: white;
    letter-spacing: 3px;
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    position: sticky;
    top: 0;
    z-index: 20;
}

/* #aaaa-6 {
    overflow: auto;
    position: relative;
    max-width: 100%;
    height: 400px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */

.overlayLoader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 300;
}

.downFloat {
    margin-bottom: 5px;
    position: fixed;
    bottom: 11vh;
    right: 2vh;
    z-index: 1;
    cursor: pointer;
    background-color: white;
    padding: 11px 15px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.allDates_Sched {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.backToTop {
    margin-bottom: 5px;
    position: fixed;
    right: 2vh;
    z-index: 1;
    background-color: white;
    cursor: pointer;
    padding: 10px 17px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.shareButton {
    position: fixed;
    right: 2vh;
    cursor: pointer;
    z-index: 1;
    background-color: white;
    padding: 10px 16px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.Quot_Sched {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
}

ul {
    margin-left: 10px !important;
}

.finalTag {
    border: 1px solid rgb(170, 132, 64);
    padding: 0px 10px;
    border-radius: 2rem;
    color: white;
    background-color: rgb(170, 132, 64);
    font-size: smaller;
    margin-left: 10px;
}

.Quot_SchedDate {
    display: flex;
    justify-content: space-between;
    width: 30%;
    padding: 1.2rem 0;
}

.wholeDateEvent {
    padding: 3rem;
    min-width: 220px;
}

.Date_Event {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    padding: 1rem 0;
    color: rgb(179, 149, 135);
    text-align: left;
}


.quote_navDiv {
    overflow-x: auto;
    white-space: nowrap;
    height: 4rem;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
}

.quote_navDiv::-webkit-scrollbar {
    height: 7px;
}

/* Track */
.quote_navDiv::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.quote_navDiv::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.quote_navDiv::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.mCSB_buttonRight {
    background-image: none !important;
}

.mCSB_buttonLeft {
    background-image: none !important;
}

.mCSB_scrollTools .mCSB_buttonRight::after {
    content: ">" !important;
    position: absolute !important;
    top: -14px !important;
    right: inherit;
    color: #000 !important;
    font-size: 30px !important;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
}

.mCSB_scrollTools .mCSB_buttonLeft::after {
    content: "<" !important;
    position: absolute !important;
    top: -14px !important;
    left: inherit;
    color: #000 !important;
    font-size: 30px !important;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
}

.Quot_Title {
    font-size: 3.1rem;
    font-weight: bolder;
    letter-spacing: 0.4rem;
    color: rgb(159, 120, 102);
    text-align: left;
}

li {
    padding: 0.6rem 0;
    font-size: 1.3rem;
}

.wedding__gallery li img {
    height: 370px;
    width: auto;
    margin-right: 10px;
}

.wedding__gallery li {
    display: inline-block;
}

ul {
    padding: 1rem 0 !important;
}

.location_Title {
    font-size: 1.6rem;
    color: rgb(159, 120, 102);
    text-align: left;
}

.gathering_Title {
    font-size: 1.6rem;
    color: rgb(159, 120, 102);
    text-align: left;
}

#aaaa-6::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
}


#mCSB_1_scrollbar_horizontal {
    margin-left: 15% !important;
    margin-right: 15% !important;
}

.customScroller.aaaa ul,
.vertical-images.aaaa ul {
    margin: 0;
    padding: 0;
    list-style: none;
    /* overflow: hidden; */
}

.customScroller.aaaa li {
    margin: 0 3px;
    float: left;
}

.vertical-images.aaaa li {
    margin: 3px 0;
}

.customScroller.aaaa li:first-child {
    margin-left: 0;
}

.vertical-images.aaaa li:first-child {
    margin-bottom: 3px;
    margin-top: 0;
}

.customScroller.aaaa li:last-child {
    margin-right: 0;
}

.vertical-images.aaaa li:last-child {
    margin-bottom: 0;
}

.customScroller.aaaa li img {
    height: 370px;
    width: auto;
    padding: 0;
}

#aaaa-6 .mCSB_draggerRail {
    display: none !important;
}

.mCSB_dragger_bar {
    background-color: #FBF7F4 !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 40px !important;
    height: 31px !important;
    top: -13px !important;
}

.runnerUp {
    background-image: url("./Quotation_images/badge-backdrop.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.loader {
    border: 4px solid gray;
    border-top: 4px solid blue;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.65s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.mCSB_draggerContainer::after {
    background-color: rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    width: 98% !important;
    left: 1% !important;
    right: 1% !important;
    top: 7px !important;
    position: absolute !important;
    height: 2px !important;
}

@media screen and (max-width: 1024px) {
    .grid-container {
        grid-column-gap: 24px;
        grid-template-columns: repeat(8, 1fr);
    }

    .m-chain-grid-container {
        grid-column-gap: 16px;
        grid-template-columns: repeat(12, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .slider-images {
        max-width: 330px;
        max-height: 210px;
        width: auto;
        height: auto;
    }

    .backToTop {
        padding: 6px 13px;
    }

    .downFloat {
        padding: 6px 11px;
    }

    .shareButton {
        padding: 6px 12px;
    }

    #aaaa-6,
    #aaaa-66 {
        width: 100%;
        background-size: contain;
        height: 230px;
    }
}


#aaa-6::-webkit-scrollbar:horizontal {
    width: 70%;
}

.event-detail {
    font-size: 1.6rem;
    margin-top: 3rem;
    text-align: left;
    color: rgb(159, 120, 102);
    font-weight: 900;
}

.event-venue, .event-gathering {
    font-size: 0.9rem;
    margin-top: 0.1rem;
    text-align: left;
    color: rgb(159, 120, 102);
}

.termsCondition {
    padding: 5rem 8rem;
}

.shadowClass {
    text-shadow: 2px 2px 4px rgba(250, 250, 250, 0.7)
}

.Quot_TeamDiv {
    padding: 5rem 8rem;
    color: white;
    background: url('./Quotation_images/pre_post.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.portQuotTeam {
    padding: 4rem;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(https://assets.graphia.in/site_media/homepage_assets/slider/wedding/_75B5996.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.delivery_detail {
    padding-bottom: 1rem;
    text-align: left;
}

.availablity-ex {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 4rem;
}

.egLogo {
    width: 15rem;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
}


.available-dev {
    font-size: 2.4rem;
    /* font-weight: 600; */
    text-align: center;
    line-height: 2;
    padding-bottom: 4rem;
}

.popupOverlay {
    min-width: 40%;
    width: fit-content;
    height: fit-content;
    background-color: white;
    padding: 1rem 2rem;
}

.coverage_till {
    color: rgb(159, 120, 102);
    font-size: 1.2rem;
    display: flex;
    padding: 0.4rem 0;
    justify-content: flex-start;
}

.available-mem {
    font-size: 2rem;
    text-align: center;
}

.footerTitle {
    font-size: 3rem;
    padding: 1rem 0
}

.allDates_Sched {
    width: 100%;
}

.pricing-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 2.4rem;
    line-height: 2.2;
    padding: 2rem 4rem;
    color: rgb(159, 120, 102);
}

.Quot_point_first {
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 1rem;
    color: rgb(159, 120, 102);
}

.Quot_point {
    font-size: 1.4rem;
    text-align: center;
    line-height: 2.5rem;
    font-weight: 600;
    padding: 0 4rem;
    color: rgb(159, 120, 102);
}

.additional-quot li {
    font-size: 1.8rem !important;
    color: rgb(159, 120, 102);
    padding: inherit;
}

.paid-tag {
    font-size: 1rem;
    background-color: rgb(159, 120, 102);
    padding: 3px 6px;
    border-radius: 7px;
    vertical-align: middle;
    color: white;
}

.pay-btn {
    border: 2px solid rgb(159, 120, 102);
    width: fit-content;
    margin: 4px 6px;
    padding: 5px 20px;
    border-radius: 7px;
    color: rgb(159, 120, 102);
    background-color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.2rem !important;
}

.emi-str {
    font-size: 16px;
    margin-top: 4px;
    font-weight: 600;
    color: rgb(159, 120, 102);
}

.adv-emi-str {
    font-size: 16px;
    margin-top: -2px;
    font-weight: 600;
    color: rgb(159, 120, 102);
}

.due-date {
    font-size: 1.2rem !important;
    font-weight: 600;
}

.divideDiv {
    min-width: 5px;
    min-height: 15rem;
    background-color: rgb(189, 200, 222);
    margin: 10rem 1rem;
}

.Quot_Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bolder;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.70);
    color: white;
}

.Delivery_Duration {
    width: 35%;
}

.eventDate-Details {
    background-color: rgb(233, 196, 187);
    color: white;
    padding: 5rem 0;
}

.indi-details {
    padding: 0 8rem;
}

.del-div {
    padding: 5rem 8rem;
    display: flex;
    align-items: center;
}

.sec_Footer {
    display: flex;
    justify-content: space-around;
    width: 25%;
}

.part-Details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.indi-part {
    padding-right: 4rem;
    text-align: left;
    width: 50%;
}

.cp-subheader {
    font-size: 1.4rem;
    padding-bottom: 1.5rem;
    color: rgb(159, 120, 102);
    text-align: left;
}

.Dur-div {
    padding: 5rem 8rem;
    margin-top: 5%;
    color: white;
    background: url('./Quotation_images/del-div.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.portDur-div {
    padding: 5rem 8rem;
    margin-top: 5%;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(https://assets.graphia.in/site_media/homepage_assets/slider/wedding/e.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.addOn-div {
    background-color: rgb(233, 196, 187);
    color: rgb(179, 149, 135);
    padding: 5rem 8rem
}


.footerLink {
    display: flex;
    padding: 1rem 0;
    width: 90%;
    justify-content: space-evenly;
}

.quote-div {
    padding: 5rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.whyDiv {
    padding: 5rem 8rem;
    color: white;
    background: url('./Quotation_images/whyEventgraphia.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.portImg {
    padding: 5rem 8rem;
    color: white;
    margin-top: 15%;
    background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(https://assets.graphia.in/site_media/homepage_assets/slider/wedding/f.jpg);
    background-size: cover;
    background-position: center;
}

.breakLine {
    margin: 5rem 0;
    background-color: rgb(159, 120, 102);
}

.ex-div {
    background-color: rgb(233, 196, 187);
    color: rgb(159, 120, 102);
    padding: 5rem 8rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.terms-point {
    font-weight: bolder;
}

.payPolicy-div {
    padding: 5rem 8rem
}


.cancPolicy-div {
    padding: 0rem 8rem 6rem 8rem
}


.custom-table {
    width: 100%;
    border-collapse: collapse;
    border: .13rem solid #333;
}

.custom-table th,
.custom-table td {
    border: .13rem solid #333;
    padding: 0.8rem;
    text-align: center;
}

.whyImg {
    width: 40%;
}


li {
    text-align: left;
}


.whyText {
    padding: 2rem 0;
    font-size: 1.3rem;
    text-align: left;
}

.custom-table td {
    font-size: 1.7rem;
    color: rgb(159, 120, 102);
}

.custom-table th {
    background-color: transparent;
    font-size: 2.2rem;
    color: rgb(159, 120, 102);
}

.whyImgTag {
    width: 85%;
}

@media screen and (max-width: 1520px) {
    .Delivery_Duration {
        width: 46%
    }

    .eventDate-Details {
        padding: 6rem 0;
    }

    .indi-details {
        padding: 0 6rem;
    }

    .Quot_TeamDiv {
        padding: 6rem;
    }

    .del-div {
        padding: 6rem;
    }

    .Dur-div {
        padding: 6rem;
    }

    .ex-div {
        padding: 6rem;
    }

    .payPolicy-div {
        padding: 6rem;
    }

    .addOn-div {
        padding: 6rem;
    }

    .whyDiv {
        padding: 3rem 6rem;
    }

    .termsCondition {
        padding: 3rem 6rem;
    }

    .portImg {
        padding: 3rem 6rem;
    }

    .indi-part {
        padding-right: 3rem 6rem;
    }

}

@media screen and (max-width: 1415px) {
    .Quot_Title {
        font-size: 2.5rem;
        font-weight: bolder;
        letter-spacing: 0.1rem;
    }

    li {
        padding: 0.3rem 0;
        font-size: 1.1rem;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 30%;
    }

    .Date_Event {
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        padding: 0.5rem 0;
    }

    .aim-point {
        font-size: 3.5rem;
    }

    .pricing-detail {
        padding: 2rem;
    }

}

@media screen and (max-width: 1200px) {
    .cancPolicy-div {
        padding: 4rem;
    }

    .pricing-detail {
        padding: 2rem 0;
    }

    .eventDate-Details {
        color: white;
        padding: 4rem 0;
    }

    .indi-details {
        padding: 0 4rem;
    }

    .whyDiv {
        padding: 4rem;
        background-position: center;
    }

    .termsCondition {
        padding: 3rem 4rem;
    }

    .portImg {
        padding: 4rem;
    }

    .del-div {
        padding: 4rem 4rem;
        display: flex;
        align-items: center;
    }

    .payPolicy-div {
        padding: 4rem;
    }

    .ex-div {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .quote-div {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .addOn-div {
        padding: 4rem;
    }

    .Dur-div {
        padding: 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-position: center;
    }

    .portDur-div {
        padding: 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-position: center;
    }

    .Quot_SchedDate {
        width: 40%;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .indi-part {
        padding-right: 7rem;
        text-align: left;
    }

    .allDates_Sched {
        width: 70%;
    }

    .wholeDateEvent {
        padding: 1rem 3rem;
    }

    .Quot_TeamDiv {
        background-position: center;
        padding: 4rem;
    }

    .portQuotTeam {
        background-position: center;
    }
}

@media screen and (max-width: 1000px) {
    .part-Details {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .popupOverlay {
        min-width: 70%;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .divideDiv {
        margin: 6rem 1rem;
        min-height: 10rem;
    }

    .pricing-detail {
        font-size: 1.7rem;
    }

    .indi-part {
        width: 100%;
    }

}

@media screen and (max-width: 900px) {
    .Quot_TeamDiv {
        padding: 3rem 4rem;
    }

    .portQuotTeam {
        padding: 3rem 4rem;
    }

    .Quot_Sched {
        padding: 5rem 0;
    }

    .egLogo {
        width: 12rem;
    }

    .aim-point {
        font-size: 3rem;
    }

    .eventDate-Details {
        padding: 3rem 0;
    }

    .whyDiv {
        padding: 3rem 4rem;
    }

    .portImg {
        padding: 3rem 4rem;
    }

    .Quot_point {
        padding: 0;
    }

    .ex-div {
        padding: 3rem 4rem;
    }

    .del-div {
        padding: 2rem;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .portDur-div {
        padding: 3rem 4rem;
    }

    .payPolicy-div {
        padding: 3rem 4rem;
    }

    .addOn-div {
        padding: 3rem 4rem;
    }

    .quote-div {
        padding: 3rem 4rem;
    }

    .wholeDateEvent {
        padding: 1rem 2rem;
    }

    .allDates_Sched {
        width: 80%;
    }
}

@media screen and (max-width: 750px) {

    .cp-subheader {
        font-size: 1.5rem;
    }

    .cancPolicy-div {
        padding: 2rem 3.5rem;
    }

    .Quot_Sched {
        padding: 2rem 0;
    }

    .Quot_SchedDate {
        width: 60%;
    }

    .eventDate-Details {
        padding: 2rem 0;
    }

    .indi-details {
        padding: 0 3.5rem;
    }

    .whyDiv {
        padding: 2rem 3.5rem;
    }

    .termsCondition {
        padding: 2rem 3.5rem;
    }

    .portImg {
        padding: 2rem 3.5rem;
    }

    .Quot_Title {
        font-size: 2rem;
    }

    .availablity-ex {
        font-size: 2rem;
    }

    .divideDiv {
        margin: 3rem 1rem;
        min-height: 8rem;
    }

    .divideM {
        min-width: 10rem;
        height: 5px;
        background-color: rgb(189, 200, 222);
    }

    .available-dev {
        font-size: 1.6rem;
    }

    .available-mem {
        font-size: 1.3rem;
    }

    .ex-div {
        padding: 2rem 3.5rem;
    }

    .quote-div {
        padding: 2rem 3.5rem;
    }

    .pricing-detail {
        font-size: 1.7rem;
    }

    .Quot_point_first {
        font-size: 1.2rem;
        padding-bottom: 2rem;
    }

    .Quot_point {
        font-size: 1.2rem;
    }

    .custom-table td {
        font-size: 1.5rem;
    }

    .custom-table th {
        font-size: 1.9rem;
    }

    .additional-quot li {
        font-size: 1.5rem !important;
    }

    .due-date, .pay-btn {
        font-size: 1rem !important;
    }

    .paid-tag {
        font-size: 0.8rem !important;
    }

    .emi-str, .adv-emi-str {
        font-size: 12px;
    }

    .addOn-div {
        padding: 2rem 3.5rem;
    }

    .payPolicy-div {
        padding: 2rem 3.5rem;
    }

    .del-div {
        padding: 2rem 3.5rem;
    }

    .Quot_TeamDiv {
        padding: 2rem 3.5rem;
    }

    .portQuotTeam {
        padding: 2rem 3.5rem;
    }

    .Dur-div {
        padding: 2rem 3.5rem;
    }

    .portDur-div {
        padding: 2rem 3.5rem;
    }

    .wholeDateEvent {
        padding: 0.5rem 2rem;
    }
}

@media screen and (max-width: 700px) {
    .backToTop {
        bottom: 17vh;
    }

    .shareButton {
        bottom: 10vh;
    }
}

@media screen and (max-width: 600px) {
    .Quot_Title {
        font-size: 1.4rem;
        text-align: left;
    }

    .popupOverlay {
        min-width: 90%;
        padding: 1rem;
    }

    .custom-table th,
    .custom-table td {
        border: .13rem solid #333;
        padding: 1rem 0.5rem;
        text-align: center;
    }


    .wedding__gallery li img {
        height: 210px;
        width: auto;
        margin-right: 10px;
    }

    .customScroller.aaaa li img {
        height: 210px;
        width: auto;
        padding: 0;
    }

    .aim-point {
        font-size: 2.5rem;
    }

    .whyText {
        font-size: 1rem;
    }

    #mCSB_1_scrollbar_horizontal {
        margin-left: 2% !important;
        margin-right: 2% !important;
        margin-top: 5% !important;
    }

    .indi-part {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Date_Event {
        font-size: 1.1rem;
    }

    .del-div {
        padding: 2rem 2.5rem;
    }

    .portDur-div {
        padding: 2rem 2.5rem;
    }

    li {
        font-size: 1rem;
    }

    .addOn-div {
        padding: 2rem 2.5rem;
    }

    .coverage_till {
        font-size: 0.9rem;
        text-align: center;
    }

    .location_Title {
        font-size: 1.5rem;
        text-align: center;
    }

    .availablity-ex {
        font-size: 1.3rem;
        padding-bottom: 2rem;
    }

    .available-dev {
        font-size: 1.4rem;
        padding-bottom: 2rem;
        line-height: 1.3;
        font-weight: 400;
    }

    .ex-div {
        padding: 2rem 2.5rem;
    }

    .eventDate-Details {
        padding: 2rem 0;
    }

    .indi-details {
        padding: 0 2.5rem;
    }

    .whyDiv {
        padding: 2rem;
    }

    .colButton {
        mask-image: linear-gradient(transparent 5%, rgb(172, 169, 169));
    }

    .termsCondition {
        padding: 2rem 2.5rem;
    }

    .portImg {
        padding: 2rem 2.5rem;
    }

    .Quot_TeamDiv {
        padding: 2rem 2.5rem;
    }

    .portQuotTeam {
        padding: 2rem 2.5rem;
    }

    .pricing-detail {
        font-size: 1.7rem;
    }

    .quote-div {
        padding: 2rem 2.5rem;
    }

    .Quot_point_first {
        font-size: 1rem;
    }

    .Quot_point {
        font-size: 1rem;
    }

    .Quot_Footer {
        font-size: 0.8rem;

    }

    .custom-table td {
        font-size: 1.2rem;
    }

    .custom-table th {
        font-size: 1.6rem;
    }

    .cp-subheader {
        font-size: 1.2rem;
    }

    .additional-quot li {
        font-size: 1.2rem !important;
    }

    .due-date, .pay-btn {
        font-size: 0.8rem !important;
    }

    .breakLine {
        margin: 2rem 0;
    }

    .cancPolicy-div {
        padding: 2rem 2.5rem
    }


    .payPolicy-div {
        padding: 2rem 2.5rem;
    }

    .footerTitle {
        font-size: 2rem;
    }

    .sec_Footer {
        width: 70%;
        font-size: 0.9rem;
    }

    .footerLink {
        padding: 1rem 0;
    }

    .event-detail {
        margin-top: 1.3rem;
    }

    .wholeDateEvent {
        padding: 0.5rem 1.4rem;
    }

}

@media screen and (max-width: 500px) {
    .Date_Event {
        font-size: 0.9rem;
    }

    .Quot_Title {
        font-size: 1.3rem;
    }

    .Quot_SchedDate {
        width: 75%;
    }

    .event-detail {
        font-size: 1.1rem;
        margin-top: 1rem;
    }

    .event-venue, .event-gathering {
        font-size: 0.8rem;
    }

    .location_Title {
        font-size: 1.2rem;
    }

    .delivery_detail {
        font-size: 0.9rem;
    }

    .indi-details {
        padding: 0 2rem;
    }

    .Dur-div {
        padding: 2rem 1rem 2rem 1.5rem;
    }

    .portDur-div {
        padding: 2rem;
    }

    .ex-div {
        padding: 2rem;
    }

    .availablity-ex {
        font-size: 1.3rem;
    }

    .available-dev {
        font-size: 1.2rem;
    }

    .available-mem {
        font-size: 1.2rem;
    }

    .quote-div {
        padding: 2rem;
    }

    .pricing-detail {
        font-size: 1.2rem;
        padding: 1rem 0;
    }

    .Quot_point {
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.3rem;
    }

    .cp-subheader {
        padding-top: .2rem;
        font-size: .9rem;
    }

    .custom-table td {
        font-size: .9rem;
    }

    .custom-table th {
        font-size: 1.1rem;
    }

    .additional-quot li {
        font-size: 1rem !important;
    }

    .addOn-div {
        padding: 2rem;
    }

    .cancPolicy-div {
        padding: 2rem;
    }

    .payPolicy-div {
        padding: 2rem;
    }

    .footerTitle {
        font-size: 1.5rem;
    }

    .sec_Footer {
        width: 100%;
        font-size: 0.7rem;
    }

    .rights {
        font-size: 0.8rem;
        margin-top: 5%;
    }

    .allDates_Sched {
        width: 100%;
    }

    .eventDate-Details {
        padding: 2rem;
    }


    .egLogo {
        width: 35%;
    }

    .Quot_TeamDiv {
        padding: 2rem;
    }

    .portQuotTeam {
        padding: 2rem;
    }
}