.scrollThumb {
    /* background-image: url("https://assets.graphia.in/site_media/logo/eg_logo_slider_scroll.png") !important; */
    background-color: #FBF7F4 !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 45px !important;
    height: 45px !important;
    position: absolute;
}

.mySlider {
    overflow-x: scroll;
    white-space: nowrap;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.mySlider::-webkit-scrollbar {
    display: none !important;
}

.mainBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    position: relative;
}

.navRight {
    display: inline-block;
    position: absolute;
    left: 2.5%;
    font-size: 1.4rem;
    cursor: pointer;
}

.navLeft {
    display: inline-block;
    position: absolute;
    right: 2.5%;
    font-size: 1.4rem;
    cursor: pointer;
}

.seek_slider {
    cursor: pointer;
    background-color: #888;
    height: 2px;
    width: 90%;
    display: flex;
    align-items: center;
}