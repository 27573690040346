.topHeader {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 10px 0;
    padding-top: 70px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: fixed;
    z-index: 15;
    width: 100%;
    top: 0;
    color: white;
}

.headerPara {
    margin: 0 5rem;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
}

.feedbacksContainer {
    width: 60%;
    display: inline-block;
    padding-bottom: 100px;
}

.feedbackDiv {
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 3rem;
}

.rateDiv {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

.raterNumber {
    width: 40px;
    height: 35px;
    color: #353434;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 600;
}

.quesLine {
    padding-bottom: 1rem;
    font-weight: 600;
}

.authorName {
    font-size: 14px;
    color: white;
    padding: 4px 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feedbackInput {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 7px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 90%;
    height: 80px;
}

.submitBtnDiv, .editBtnDiv{
    display: flex;
    justify-content: end;
    margin-right: 2.6rem;
}

.submitFdbkBtn, .editFdbkBtn {
    background-color: #0D7575;
    padding: 10px 22px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    min-width: 140px;
    max-width: 140px;
    margin: 15px;
}

.hrDivision {
    padding: 0px;
    margin-bottom: 15px;
    opacity: 0.1;
}

@media screen and (max-width:1200px) {
    .submitBtnDiv, .editBtnDiv{
        justify-content: center;
        margin-right: auto;
    }

    .raterNumber {
        line-height: 34px;
    }
}

@media screen and (max-width:900px) {
    .raterNumber {
        width: 30px;
        height: 30px;
        font-size: 13px;
        line-height: 26px;
    }
}

@media screen and (max-width:680px) {
    .feedbacksContainer {
        width: 90%;
    }

    .raterNumber {
        width: 25px;
        height: 30px;
        border-radius: 9px;
        font-size: 13px;
        line-height: 27px;
    }

    .submitFdbkBtn, .editFdbkBtn {
        font-size: 13px;
        padding: 6px;
    }

    .feedbackInput {
        font-size: 13px;
    }

    .authorName {
        font-size: 13px;
    }


}

