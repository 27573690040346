@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Petit+Formal+Script&family=Style+Script&display=swap');

@font-face {
    font-family: 'artecallya';
    src: url('../../fonts/artecallya/Artecallya-Script.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.wg_non_aim-point {
    font-family: 'artecallya';
    font-size: 4rem;
    color: rgb(84, 77, 68);
}

.non_collapsed {
    position: relative;
    transition: height 0.5s ease-in-out;
    mask-image: linear-gradient(black 50%, transparent);
}

.non_name_title {
    font-family: 'artecallya';
    color: white;
    letter-spacing: 3px;
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
    position: sticky;
    top: 0;
    z-index: 20;
}

/* #aaaa-6 {
    overflow: auto;
    position: relative;
    max-width: 100%;
    height: 400px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
} */


.finalTag {
    border: 1px solid rgb(170, 132, 64);
    padding: 0px 10px;
    border-radius: 2rem;
    color: white;
    background-color: rgb(170, 132, 64);
    font-size: smaller;
    margin-left: 10px;
}

.overlayLoader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 300;
}

.wg_non_allDates_Sched {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.nonDownFloat {
    margin-bottom: 5px;
    position: fixed;
    bottom: 3vh;
    right: 2vh;
    z-index: 1;
    cursor: pointer;
    background-color: white;
    padding: 11px 15px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.nonBackToTop {
    margin-bottom: 5px;
    position: fixed;
    right: 2vh;
    z-index: 1;
    background-color: white;
    cursor: pointer;
    padding: 10px 17px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.nonShareButton {
    position: fixed;
    right: 2vh;
    cursor: pointer;
    z-index: 1;
    background-color: white;
    padding: 10px 16px;
    box-shadow: black;
    border-radius: 10vh;
    box-shadow: 1px 11px 17px 5px rgba(0, 0, 0, 0.4);
}

.WG_non_Quot_Sched {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
}

ul {
    margin-left: 10px !important;
}

.Quot_SchedDate {
    display: flex;
    justify-content: space-between;
    width: 30%;
    padding: 1.2rem 0;
}

.non_wholeDateEvent {
    padding: 3rem;
    min-width: 220px;
}

.wg_non_Date_Event {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    padding: 1rem 0;
    color: rgb(84, 77, 68);
    text-align: left;
}

.non_quote_navDiv {
    overflow-x: scroll;
    white-space: nowrap;
    height: 4rem;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: flex-start;
}

.mCSB_buttonRight {
    background-image: none !important;
}

.mCSB_buttonLeft {
    background-image: none !important;
}

.mCSB_scrollTools .mCSB_buttonRight::after {
    content: ">" !important;
    position: absolute !important;
    top: -14px !important;
    right: inherit;
    color: #000 !important;
    font-size: 30px !important;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
}

.mCSB_scrollTools .mCSB_buttonLeft::after {
    content: "<" !important;
    position: absolute !important;
    top: -14px !important;
    left: inherit;
    color: #000 !important;
    font-size: 30px !important;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
}

.WG_non_Quot_Title, .WG_non_Quot_Title_Light {
    font-size: 3.1rem;
    font-weight: bolder;
    letter-spacing: 0.4rem;
    text-align: left;
}

.WG_non_Quot_Title {
    color: rgb(84, 77, 68);
}

.WG_non_Quot_Title_Light {
    color: rgb(134, 126, 116);
}

li {
    padding: 0.6rem 0;
    font-size: 1.3rem;
}

.wedding__gallery li img {
    height: 370px;
    width: auto;
    margin-right: 10px;
}

.wedding__gallery li {
    display: inline-block;
}

ul {
    padding: 1rem 0 !important;
}

.wg_non_location_Title {
    font-size: 1.6rem;
    color: rgb(84, 77, 68);
    text-align: left;
}

.non_gathering_Title {
    font-size: 1.6rem;
    color: rgb(84, 77, 68);
    text-align: left;
}

#aaaa-6::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: none;
}


#mCSB_1_scrollbar_horizontal {
    margin-left: 15% !important;
    margin-right: 15% !important;
}

.customScroller.aaaa ul,
.vertical-images.aaaa ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}

.customScroller.aaaa li {
    margin: 0 3px;
    float: left;
}

.vertical-images.aaaa li {
    margin: 3px 0;
}

.customScroller.aaaa li:first-child {
    margin-left: 0;
}

.vertical-images.aaaa li:first-child {
    margin-bottom: 3px;
    margin-top: 0;
}

.customScroller.aaaa li:last-child {
    margin-right: 0;
}

.vertical-images.aaaa li:last-child {
    margin-bottom: 0;
}

.customScroller.aaaa li img {
    height: 370px;
    width: auto;
    padding: 0;
}

#aaaa-6 .mCSB_draggerRail {
    display: none !important;
}

.mCSB_dragger_bar {
    background-color: #FBF7F4 !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 40px !important;
    height: 31px !important;
    top: -13px !important;
}

.runnerUp {
    background-image: url("https://assets.graphia.in/site_media/react/badge-backdrop.png");
    background-repeat: no-repeat;
    background-position: center;
}


.mCSB_draggerContainer::after {
    background-color: rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    width: 98% !important;
    left: 1% !important;
    right: 1% !important;
    top: 7px !important;
    position: absolute !important;
    height: 2px !important;
}

@media screen and (max-width: 1024px) {
    .grid-container {
        grid-column-gap: 24px;
        grid-template-columns: repeat(8, 1fr);
    }

    .m-chain-grid-container {
        grid-column-gap: 16px;
        grid-template-columns: repeat(12, 1fr);
    }
}

@media screen and (max-width: 600px) {
    .slider-images {
        max-width: 330px;
        max-height: 210px;
        width: auto;
        height: auto;
    }

    .nonBackToTop {
        padding: 6px 13px;
    }

    .nonDownFloat {
        padding: 6px 11px;
    }

    .nonShareButton {
        padding: 6px 12px;
    }

    #aaaa-6,
    #aaaa-66 {
        width: 100%;
        background-size: contain;
        height: 230px;
    }
}


#aaa-6::-webkit-scrollbar:horizontal {
    width: 70%;
}

.wg_non_event-detail {
    font-size: 1.6rem;
    margin-top: 3rem;
    text-align: left;
    color: rgb(84, 77, 68);
    font-weight: 900;
}

.non_wg-event-venue, .wg_non_event-gathering {
    font-size: 0.9rem;
    margin-top: 0.1rem;
    text-align: left;
    color: rgb(84, 77, 68);
}

.non_termsCondition {
    padding: 5rem 8rem;
}

.non_WG_Quot_TeamDiv {
    padding: 5rem 8rem;
    color: rgb(84, 77, 68);
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.non_WGPortQuotTeam {
    padding: 4rem;
    color: rgb(84, 77, 68);
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.non_delivery_detail {
    padding-bottom: 1rem;
    text-align: left;
}

.wg_non_availablity-ex {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 4rem;
}

.egLogo {
    width: 15rem;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
}

.wg_non_available-dev {
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
    line-height: 2;
    padding-bottom: 4rem;
}

.non_popupOverlay {
    min-width: 40%;
    width: fit-content;
    height: fit-content;
    background-color: white;
    padding: 1rem 2rem;
}

.wg_non_coverage_till {
    color: rgb(84, 77, 68);
    font-size: 1.2rem;
    display: flex;
    padding: 0.4rem 0;
    justify-content: flex-start;
}

.wg_non_available-mem {
    font-size: 2rem;
    text-align: center;
}

.footerTitle {
    font-size: 3rem;
    padding: 1rem 0
}

.wg_non_allDates_Sched {
    width: 100%;
}

.non_pricing-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    font-size: 2.4rem;
    line-height: 2.2;
    padding: 2rem 4rem;
    color: rgb(84, 77, 68);
}

.wg_non_Quot_point_first {
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 1rem;
    color: rgb(84, 77, 68);
}

.wg_non_Quot_point {
    font-size: 1.4rem;
    text-align: center;
    line-height: 2.5rem;
    font-weight: 600;
    padding: 0 4rem;
    color: rgb(84, 77, 68);
}

.wg_non_additional-quot li {
    font-size: 1.8rem !important;
    color: rgb(84, 77, 68);
    padding: inherit;
}

.wg_non_paid-tag {
    font-size: 1rem;
    background-color: rgb(84, 77, 68);
    padding: 3px 6px;
    border-radius: 7px;
    vertical-align: middle;
    color: white;
}

.wg_non_pay-btn {
    border: 2px solid rgb(84, 77, 68);
    width: fit-content;
    margin: 4px 6px;
    padding: 5px 20px;
    border-radius: 7px;
    color: rgb(84, 77, 68);
    background-color: white;
    font-weight: 600;
    cursor: pointer;
    font-size: 1.2rem !important;
}

.wg_non_due-date {
    font-size: 1.2rem !important;
    font-weight: 600;
}

.divideDiv {
    min-width: 5px;
    min-height: 15rem;
    background-color: rgb(189, 200, 222);
    margin: 10rem 1rem;
}

.non_Quot_Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bolder;
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.70);
    color: white;
}

.Delivery_Duration {
    width: 35%;
}

.wg_non_eventDate-Details {
    background-color: rgb(212, 191, 172);
    color: white;
    padding: 5rem 0;
}

.non_indi-details {
    padding: 0 8rem;
}

.wg_non_del-div {
    padding: 5rem 8rem;
    display: flex;
    background-color: rgb(212, 191, 172);
    align-items: center;
}

.sec_Footer {
    display: flex;
    justify-content: space-around;
    width: 25%;
}

.non_part-Details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.non_indi-part {
    padding-right: 8rem;
    width: 50%;
}

.wg_non_cp-subheader {
    font-size: 1.4rem;
    padding-bottom: 1.5rem;
    color: rgb(84, 77, 68);
    text-align: left;
}

.non_WGDur-div {
    padding: 5rem 8rem;
    margin-top: 0;
    background: none;
    color: rgb(84, 77, 68);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.non_WGPortDur-div {
    padding: 5rem 8rem;
    margin-top: 0;
    color: rgb(84, 77, 68);
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.wg_non_addOn-div {
    background-color: rgb(212, 191, 172);
    color: rgb(84, 77, 68);
    padding: 5rem 8rem
}


.non_footerLink {
    display: flex;
    padding: 1rem 0;
    width: 90%;
    justify-content: space-evenly;
}

.non_quote-div {
    padding: 5rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.non_WGWhyDiv {
    padding: 0;
    color: rgb(84, 77, 68);
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0;
}

.portImage {
    padding: 5rem 8rem;
    color: white;
    background: linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(../WGQuotation/Quotation_images/Akshay.JPG);
    background-size: cover;
    background-position: center;
}

.breakLine {
    margin: 5rem 0;
    background-color: rgb(84, 77, 68);
}

.wg_non_ex-div {
    background-color: rgb(212, 191, 172);
    color: rgb(84, 77, 68);
    padding: 5rem 8rem;
    display: flex;
    flex-direction: column;
    align-items: center
}

.non_terms-point {
    font-weight: bolder;
}

.non_payPolicy-div {
    padding: 5rem 8rem
}


.wg_non_cancPolicy-div {
    padding: 0rem 8rem 6rem 8rem
}


.wg-non-custom-table {
    width: 100%;
    border-collapse: collapse;
    border: .13rem solid #333;
}

.wg-non-custom-table th,
.wg-non-custom-table td {
    border: .13rem solid #333;
    padding: 0.8rem;
    text-align: center;
}

.whyImg {
    width: 40%;
}


li {
    text-align: left;
}


.non_whyText {
    padding: 2rem 0;
    font-size: 1.3rem;
    text-align: left;
}

.wg-non-custom-table td {
    font-size: 1.7rem;
    color: rgb(84, 77, 68);
}

.wg-non-custom-table th {
    background-color: transparent;
    font-size: 2.2rem;
    color: rgb(84, 77, 68);
}

.whyImgTag {
    width: 85%;
}

@media screen and (max-width: 1520px) {
    .Delivery_Duration {
        width: 46%
    }

    .wg_non_eventDate-Details {
        padding: 6rem 0;
    }

    .non_indi-details {
        padding: 0 6rem;
    }

    .non_WGWhyDiv {
        margin-top: 0;
    }

    .non_termsCondition {
        padding: 3rem 6rem;
    }

    .portImage {
        padding: 4rem 6rem;
    }

    .non_indi-part {
        padding-right: 6rem;
    }

    .non_whyText {
        padding: 0.8rem 0;
    }

    .non_WG_Quot_TeamDiv {
        padding: 6rem;
    }

    .wg_non_del-div {
        padding: 6rem;
    }

    .non_WGDur-div {
        padding: 6rem;
    }

    .wg_non_ex-div {
        padding: 6rem;
    }

    .non_quote-div {
        padding: 6rem;
    }

    .non_payPolicy-div {
        padding: 6rem;
    }

    .wg_non_addOn-div {
        padding: 6rem;
    }

}

@media screen and (max-width: 1415px) {
    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 2.5rem;
        font-weight: bolder;
        letter-spacing: 0.1rem;
    }

    .non_WGWhyDiv li {
        padding: 0.3rem 0;
        font-size: 1.1rem;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 30%;
    }

    .wg_non_Date_Event {
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        padding: 0.5rem 0;
    }

    .wg_non_aim-point {
        font-size: 3.5rem;
    }

    .non_pricing-detail {
        padding: 2rem;
    }

}

@media screen and (max-width: 1200px) {
    .wg_non_cancPolicy-div {
        padding: 4rem;
    }

    .non_pricing-detail {
        padding: 2rem 0;
    }

    .wg_non_eventDate-Details {
        color: white;
        padding: 4rem 0;
    }

    .non_indi-details {
        padding: 0 4rem;
    }

    .non_WG_Quot_TeamDiv {
        padding: 4rem;
    }

    .non_WGWhyDiv {
        background-position: center;
    }

    .non_termsCondition {
        padding: 3rem 4rem;
    }

    .portImage {
        padding: 4rem;
    }

    .wg_non_del-div {
        padding: 4rem 4rem;
        display: flex;
        align-items: center;
    }

    .non_payPolicy-div {
        padding: 3rem 4rem;
    }

    .wg_non_ex-div {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .non_quote-div {
        padding: 3rem 4rem;
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .wg_non_addOn-div {
        padding: 3rem 4rem
    }

    .non_WGDur-div {
        padding: 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-position: center;
    }

    .non_WGPortDur-div {
        padding: 3rem 4rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-position: center;
    }

    .Quot_SchedDate {
        width: 40%;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .non_indi-part {
        padding-right: 7rem;
    }

    .wg_non_allDates_Sched {
        width: 70%;
    }

    .non_wholeDateEvent {
        padding: 1rem 3rem;
    }

    .non_WG_Quot_TeamDiv {
        background-position: center;
    }

    .non_WGPortQuotTeam {
        background-position: center;
    }

    .non_whyText {
        padding: 1rem 0;
        font-size: 1rem;
    }

    .non_WGWhyDiv li {
        font-size: 0.8rem;
    }

    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 1000px) {
    .non_part-Details {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .popupOverlay {
        min-width: 70%;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .divideDiv {
        margin: 6rem 1rem;
        min-height: 10rem;
    }

    .non_pricing-detail {
        font-size: 1.7rem;
    }

    .non_indi-part {
        width: 100%;
    }

    .wg_non_event-detail {
        text-align: left;
    }

    .non_whyText {
        font-size: 1rem;
    }

    .non_WGWhyDiv li {
        font-size: 0.8rem;
    }

}

@media screen and (max-width: 900px) {
    .non_WG_Quot_TeamDiv {
        padding: 4rem 6rem;
    }

    .non_WGPortQuotTeam {
        padding: 3rem 4rem;
    }

    .WG_non_Quot_Sched {
        padding: 5rem 0;
    }

    .egLogo {
        width: 12rem;
    }

    .wg_non_aim-point {
        font-size: 3rem;
    }

    .wg_non_eventDate-Details {
        padding: 3rem 0;
    }

    .non_indi-details {
        padding: 0 4rem;
    }

    .portImage {
        padding: 3rem 4rem;
    }

    .wg_non_Quot_point {
        padding: 0;
    }

    .wg_non_ex-div {
        padding: 3rem 4rem;
    }

    .wg_non_del-div {
        padding: 3rem 4rem;
    }

    .sec_Footer {
        display: flex;
        justify-content: space-around;
        width: 50%;
    }

    .non_wholeDateEvent {
        padding: 1rem 2rem;
    }

    .wg_non_allDates_Sched {
        width: 80%;
    }

    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 1.8rem;
    }

    .non_whyText {
        padding: 0.7rem 0;
    }
}

@media screen and (max-width: 750px) {

    .wg_non_cp-subheader {
        font-size: 1.5rem;
    }

    .wg_non_cancPolicy-div {
        padding: 3.5rem;
    }

    .WG_non_Quot_Sched {
        padding: 2rem 0;
    }

    .Quot_SchedDate {
        width: 60%;
    }

    .wg_non_eventDate-Details {
        padding: 2rem 0;
    }

    .non_indi-details {
        padding: 0 3.5rem;
    }

    .non_termsCondition {
        padding: 2rem 3.5rem;
    }

    .portImage {
        padding: 2rem 3.5rem;
    }

    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 2rem;
    }

    .wg_non_availablity-ex {
        font-size: 2rem;
    }

    .divideDiv {
        margin: 3rem 1rem;
        min-height: 8rem;
    }

    .divideM {
        min-width: 10rem;
        height: 5px;
        background-color: rgb(189, 200, 222);
    }

    .wg_non_available-dev {
        font-size: 1.6rem;
    }

    .wg_non_available-mem {
        font-size: 1.4rem;
    }

    .wg_non_ex-div {
        padding: 2rem 3.5rem;
    }

    .non_quote-div {
        padding: 2rem 3.5rem;
    }

    .non_pricing-detail {
        font-size: 1.7rem;
    }

    .wg_non_Quot_point_first {
        font-size: 1.2rem;
        padding-bottom: 2rem;
    }

    .wg_non_Quot_point {
        font-size: 1.2rem;
    }

    .wg-non-custom-table td {
        font-size: 1.5rem;
    }

    .wg-non-custom-table th {
        font-size: 1.9rem;
    }

    .wg_non_additional-quot li {
        font-size: 1.5rem !important;
    }

    .wg_non_due-date, .wg_non_pay-btn {
        font-size: 1rem !important;
    }

    .wg_non_paid-tag {
        font-size: 0.8rem !important;
    }

    .wg_non_addOn-div {
        padding: 2rem 3.5rem;
    }

    .non_payPolicy-div {
        padding: 2rem 3.5rem;
    }

    .non_WG_Quot_TeamDiv {
        padding: 2rem 3.5rem;
    }

    .non_WGPortQuotTeam {
        padding: 2rem 3.5rem;
    }

    .wg_non_del-div {
        padding: 2rem 3.5rem;
    }

    .non_WGDur-div {
        padding: 2rem;
    }

    .non_WGPortDur-div {
        padding: 2rem 3.5rem;
    }

    .non_wholeDateEvent {
        padding: 0.5rem 2rem;
    }
}

@media screen and (max-width: 600px) {
    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 1.4rem;
        text-align: left;
    }

    .popupOverlay {
        min-width: 90%;
        padding: 1rem;
    }

    .wg-non-custom-table th,
    .wg-non-custom-table td {
        border: .13rem solid #333;
        padding: 1rem 0.5rem;
        text-align: center;
    }


    .wedding__gallery li img {
        height: 210px;
        width: auto;
        margin-right: 10px;
    }

    .customScroller.aaaa li img {
        height: 210px;
        width: auto;
        padding: 0;
    }

    .wg_non_aim-point {
        font-size: 2.5rem;
    }

    #mCSB_1_scrollbar_horizontal {
        margin-left: 2% !important;
        margin-right: 2% !important;
        margin-top: 5% !important;
    }

    .non_indi-part {
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wg_non_Date_Event {
        font-size: 1.1rem;
    }

    li {
        font-size: 1rem;
    }

    .wg_non_coverage_till {
        font-size: 0.9rem;
        text-align: center;
    }

    .wg_non_location_Title {
        font-size: 1.5rem;
        text-align: center;
    }

    .wg_non_availablity-ex {
        font-size: 1.3rem;
        padding-bottom: 2rem;
    }

    .wg_non_available-dev {
        font-size: 1.4rem;
        padding-bottom: 2rem;
        line-height: 1.3;
        font-weight: 400;
    }

    .wg_non_ex-div {
        padding: 2rem 2.5rem;
    }

    .wg_non_eventDate-Details {
        padding: 2rem 0;
    }

    .non_indi-details {
        padding: 0 2.5rem;
    }

    .non_colButton {
        mask-image: linear-gradient(transparent 5%, rgb(172, 169, 169));
    }

    .non_termsCondition {
        padding: 2rem 2.5rem;
    }

    .portImage {
        padding: 2rem;
    }

    .non_WG_Quot_TeamDiv {
        padding: 2rem 2.5rem;
    }

    .non_WGPortQuotTeam {
        padding: 2rem 2.5rem;
    }

    .non_pricing-detail {
        font-size: 1.7rem;
    }

    .wg_non_del-div {
        padding: 2rem 2.5rem;
    }

    .non_WGPortDur-div {
        padding: 2rem 2.5rem;
    }

    .non_quote-div {
        padding: 2rem 2.5rem;
    }

    .wg_non_Quot_point_first {
        font-size: 1rem;
    }

    .wg_non_Quot_point {
        font-size: 1rem;
    }

    .non_Quot_Footer {
        font-size: 0.8rem;

    }

    .wg-non-custom-table td {
        font-size: 1.2rem;
    }

    .wg_non_addOn-div {
        padding: 2rem 2.5rem;
    }

    .wg-non-custom-table th {
        font-size: 1.6rem;
    }

    .wg_non_cp-subheader {
        font-size: 1.2rem;
    }

    .wg_non_additional-quot li {
        font-size: 1.2rem !important;
    }

    .wg_non_due-date, .wg_non_pay-btn {
        font-size: 0.8rem !important;
    }

    .breakLine {
        margin: 2rem 0;
    }

    .wg_non_cancPolicy-div {
        padding: 2rem 2.5rem
    }

    .non_payPolicy-div {
        padding: 2rem 2.5rem;
    }

    .footerTitle {
        font-size: 2rem;
    }

    .sec_Footer {
        width: 70%;
        font-size: 0.9rem;
    }

    .non_footerLink {
        padding: 1rem 0;
    }

    .wg_non_event-detail {
        margin-top: 1.3rem;
    }

    .non_wholeDateEvent {
        padding: 0.5rem 1.4rem;
        min-width: 50%;
    }

    .wg_non_allDates_Sched {
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .wg_non_Date_Event {
        font-size: 0.9rem;
    }

    .WG_non_Quot_Title, .WG_non_Quot_Title_Light {
        font-size: 1.3rem;
    }

    .Quot_SchedDate {
        width: 75%;
    }

    .wg_non_del-div {
        padding: 2rem;
    }

    .non_termsCondition {
        padding: 2rem;
    }

    .wg_non_event-detail {
        font-size: 1.1rem;
        margin-top: 1rem;
    }

    .non_wg-event-venue, .wg_non_event-gathering {
        font-size: 0.8rem;
    }

    .wg_non_location_Title {
        font-size: 1.2rem;
    }

    .non_delivery_detail {
        font-size: 0.9rem;
    }

    .non_WGDur-div {
        padding: 2rem 1rem 2rem 1.5rem;
    }

    .non_WGPortDur-div {
        padding: 2rem;
    }

    .wg_non_ex-div {
        padding: 2rem;
    }

    .wg_non_availablity-ex {
        font-size: 1.3rem;
    }

    .wg_non_available-dev {
        font-size: 1.2rem;
    }

    .wg_non_available-mem {
        font-size: 1.2rem;
    }

    .non_quote-div {
        padding: 2rem;
    }

    .non_pricing-detail {
        font-size: 1.2rem;
        padding: 1rem 0;
    }

    .wg_non_Quot_point {
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.3rem;
    }

    .wg_non_cp-subheader {
        padding-top: .2rem;
        font-size: .9rem;
    }

    .wg-non-custom-table td {
        font-size: .9rem;
    }

    .wg-non-custom-table th {
        font-size: 1.1rem;
    }

    .wg_non_additional-quot li {
        font-size: 1rem !important;
    }

    .wg_non_addOn-div {
        padding: 2rem;
    }

    .wg_non_cancPolicy-div {
        padding: 2rem;
    }

    .non_payPolicy-div {
        padding: 2rem;
    }

    .footerTitle {
        font-size: 1.5rem;
    }

    .sec_Footer {
        width: 100%;
        font-size: 0.7rem;
    }

    .rights {
        font-size: 0.8rem;
        margin-top: 5%;
    }

    .wg_non_eventDate-Details {
        padding: 2rem 0;
    }

    .non_indi-details {
        padding: 0 2rem;
    }


    .egLogo {
        width: 50%;
    }

    .non_WG_Quot_TeamDiv {
        padding: 2rem;
    }

    .non_WGPortQuotTeam {
        padding: 2rem;
    }
}