h2 {
    font-size: 1.5em !important;
}

#face-scanner video {
    overflow: hidden;
    width: 450px;
    height: 450px;
    top: 16.5%;
    position: relative;
    object-fit: cover;
    left: 0;
    border-radius: 50%;
    margin: auto;
}

#face-suggest-h2 {
    font-family: 'Montserrat' !important;
    letter-spacing: normal !important;
    font-size: 27px !important;
}

#video {
    background: #000;
    overflow: hidden;
}

.retrySearch {
    padding: 5px 12px;
    border-radius: 5px;
    color: black;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 15px;
}

.downloadSearch {
    padding: 5px 12px;
    border-radius: 5px;
    color: white;
    background-color: #9F7866;
}

.scan {
    content: '';
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 5px;
    background: #67E1F0;
    margin-top: 62px;
    border-radius: 8px;
    filter: drop-shadow(0 0 20px #67E1F0) drop-shadow(0 0 60px #67E1F0);
    animation: animation_line 4s ease-in-out infinite;
}

.face-overlay {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    top: 20%;
}

.camera {
    position: relative;
    margin: auto;
    margin-bottom: 67px;
}

.invert-image {
    filter: invert(100%) brightness(2);
}

.face-btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    z-index: 3;
    margin-top: 40px;
    margin-bottom: 20px;
}

.face-btns button {
    font-size: 27px;
    font-family: 'Montserrat';
    padding: 10px 18px;
    border-radius: 0.3rem;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.selfie-container {
    margin: auto;
    width: 80%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selfie-container h2 {
    margin-top: 5px;
    width: 80vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 124.4%;
    letter-spacing: 0.12em;
    color: #000000;
    text-align: center;
    text-transform: capitalize;
}

.tk-slf-content {
    display: flex;
    justify-content: center;
}

#face-scanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popupOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupOverlay h1 {
    font-family: 'Montserrat';
    font-size: 22px !important;
    letter-spacing: 0.5px;
    margin-bottom: 12px;
    margin-top: -4px;
    font-weight: 700;
}

.popupOverlay h2 {
    font-family: 'Montserrat';
    font-size: 15px !important;
    margin-bottom: 20px;
    font-weight: bold;
}

.popupOverlay h3 {
    font-weight: 700;
    font-family: 'Montserrat';
    margin-bottom: 5px;
}

#fullviewH2 {
    color: #454140;
}

.popupOverlay .permissionPopup {
    max-height: 100%;
    overflow: auto;
}

.permissionPopup {
    position: relative;
    margin: 70px auto;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    width: 30%;
    text-align: left;
    transition: all 5s ease-in-out;
}

#popup-cnl {
    float: right;
    font-size: 18px;
    font-family: 'Montserrat';
    padding: 7px;
    border: none;
    border-radius: 0.3rem;
    background: black;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
}

#popup-allow {
    float: right;
    margin-right: 15px;
    font-size: 18px;
    font-family: 'Montserrat';
    padding: 7px;
    border-radius: 0.3rem;
    background: #000;
    color: #ffffff;
    cursor: pointer;
}

.errorOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMessage {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    font-weight: 700;
    width: 30%;
    text-align: left;
}

.okPhotoButton {
    float: right;
    margin-right: 15px;
    margin-top: 10px;
    font-size: 18px;
    font-family: 'Montserrat';
    padding: 7px 12px;
    border: none;
    border-radius: 0.3rem;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.cancelSearch {
    position: absolute;
    top: 20%;
    left: 3%;
}



@keyframes animation_line {

    0%,
    100% {
        top: 0%;
    }

    50% {
        top: 100%;
    }
}


.selfie-frame {
    position: relative;
    max-width: 100%;
    height: auto;
}


@media  screen and (max-width: 1200px) {
    .selfie-frame {
        width: 45%;
    }

    .cancelSearch {
        top: 12%;
        left: 5%;
    }

    #face-scanner video {
        width: 408px;
        height: 400px;
        font-size: 25px;
    }

    #face-suggest-h2 {
        font-size: 25px !important;
    }
}



@media screen and (max-width: 799px) {
    .permissionPopup {
        width: 90%;
    }

    .errorMessage {
        width: 80%;
    }

    .face-btns {
        margin-top: 15px !important;
    }

    #face-scanner video {
        width: 250px !important;
        height: 250px !important;
        max-width: none !important;
    }

    .face-overlay {
        width: 350px !important;
        top: 17% !important;
    }

    .selfie-container {
        height: 72vh !important;
    }
    
    .face-btns button {
        font-size: 20px;
    }

    #face-suggest-h2 {
        font-family: 'Montserrat' !important;
        letter-spacing: normal !important;
        font-size: 20px !important;
    }

}


@media (max-width: 768px) {
    .selfie-frame {
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .retrySearch{
        font-size: 12px;
    }
}