.copyModal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
}

.copyModalText {
    background: whitesmoke;
    padding: 1rem 2rem;
    border-radius: 5px;
    font-size: larger;
}

.copyModalBtn {
    background-color: green;
    padding: 5px 6px;
    margin-top: 10px;
    border-radius: 5px;
    float: right;
    color: white;
}
