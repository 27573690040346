.accordion {
    width: 80%;
    margin: 150px auto;
}

.condBody {
    overflow: hidden !important;
}

.accordion-heading {
    font-size: 24px;
    font-weight: 600;
}

.multipleEst {
    display: flex;
    width: fit-content;
    overflow: scroll;
    scrollbar-color: transparent transparent;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.multipleEst a {
    touch-action: pan-x;
}

.booking-id {
    width: 50%;
}

.booking-status-badge {
    padding: 3px;
    text-align: center;
}

.balance {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
    gap: 5%;
    font-size: 22px;

}

.balance-left {
    display: flex;
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.balance-left p {
    font-size: 20px;
}

.balance-right {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
}

.deliverables {
    width: 90%;
    margin: 20px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    scrollbar-color: transparent transparent;
}

.top-right {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 40px;
    background: transparent;

}

.card-text-overlay {
    text-transform: capitalize;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: transparent;
    color: white;
    font-size: 24px;
}

.card-overlay-deliverable {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
}

.del-cards,
.del-cards img {
    border-radius: 15px !important;

}

.mod-container {
    background: white;
    display: flex;
    padding: 8px;
    /* margin-bottom: 5px; */
    justify-content: space-between;
}

.mod-container p {
    background: white;

}

.modal-headers {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mod-footer {
    display: flex !important;
    justify-content: center !important;
}

.no-bookings {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
}

.no-booking-header {
    display: flex;
    justify-content: center;
}

.no-booking-header h2 {
    font-size: 30px;
    font-weight: 700;
}

.callback-section {
    display: flex;
    flex-direction: column;
    gap: 15px;

    justify-content: center;
    align-items: center;
}

.callback-section {
    font-size: 26px;
}

.eventgraphia-data {

    display: flex;
    flex-direction: row;
    border: 1px solid rgba(0, 0, 0, 0.238);
    justify-content: space-between;
}

.box-data {
    border: 1px solid rgba(0, 0, 0, 0.238);

    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.box-data p {
    width: 100%;
    font-size: 24px;
    text-align: center;
}

.box-data span {
    font-size: 90px;
}

.box-data:hover {
    background: rgba(0, 0, 0, 0.72);
    color: white;
}

.booking-detail-tab {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid rgba(32, 32, 32, 0.121);
    border-bottom: 1px solid rgba(32, 32, 32, 0.121);

}

.closeList {
    font-size: 45px;
    position: sticky;
    top: 0;
    margin-left: 90%;
    right: 3%;
}

/* .closeEvent {
    font-size: 45px;
    position: absolute;
    top: 15%;
    right: 5%;
} */

.shared-list {
    display: flex;
    overflow-y: scroll;
    background-color: white;
    flex-direction: column;
    width: 80%;
    max-height: 80vh;
    height: max-content;
}

.del-card-img {
    width: 300px;
    height: 10rem;
}

/* .shared-container{
    width: 80vw;
    height: auto;
} */
/* .event-detail-popup{
    width: 80vw!important;
} */
.shared-link-text {
    width: 300px;
    font-size: 1.2rem;
}

.dropAlbum {
    z-index: 14;
    margin-left: 90%;
    margin-top: -2%;
}

.single-deliverable {
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    justify-content: center;
    width: 40%;
    align-items: center;
}

.single-deliverable-main {
    display: flex;
    flex-direction: row;
}

.main-container-del {
    display: flex;
    flex-direction: column;
}

.single-deliverable-tab {
    display: flex;
    padding: 20px 0;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    width: 40%;
    /* justify-content: space-evenly; */
    align-items: center;
}

.single-deliverable-tab button {
    width: 45%;
    margin: 10px;
    font-size: 19px;
    text-align: center;
    justify-content: center;
}

.single-deliverable-tab a {
    width: 45%;
    margin: 10px;
    font-size: 19px;
    justify-content: center;
}

.pay-btn {
    width: 151px;
    font-size: 18px;
    text-align: center;
    justify-content: center;
}

.share-everywhere {
    display: flex;
}

.booking-details {
    width: 70%;
    margin: 50px auto;
}

.accordion-heading {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-weight: 500;
}

.shared-list li button {
    border-radius: 0.3rem;
    padding: 8px 13px;
    gap: 10px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
}

.regen,
.copy-link {
    background: #319795;
}

.disable {
    background: #ff0000;

}

.share {
    background: #34ac13;

}

.shared-list li {
    list-style: none;
    border-bottom: 1px solid #80808036;
    padding-bottom: 18px;
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    gap: 8%;
    align-items: center;
    justify-content: center;
}

.shared-link {
    /* margin-bottom: 20px; */
    font-weight: 800;
    font-style: italic;
    font-size: 18px;
    color: #319795;
    gap: 20px;
}

.shared-link,
.shared-btns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shared-btns {
    flex-direction: row;
    gap: 5%;
}

.booking-detail-panel {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2px;
    flex-direction: column;
    width: 60%;
    max-height: 80vh;
    height: max-content;
}

.booking-detail-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.booking-detail-header h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
}

.booking-detail-header p {
    font-size: 19px;

}

.details-section fieldset {
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
    border: 1px solid #c0c0c0;
}

.details-section fieldset legend {
    text-align: center;
    font-size: 20px;
    padding: 0px 20px;
    text-transform: capitalize;
    font-weight: 600;
}

.closePayment {
    background-color: white;
    border-radius: 20px;
}

.table-head {
    background-color: #00000013;
}

.share-everywhere {
    display: none;
}

.venue-detail {
    padding-top: 7px;
    padding-bottom: 7px;

}

.venue-detail li {
    list-style: none;
    border-bottom: 1px solid #80808036;
    padding-bottom: 15px;
    padding-top: 15px;
}

.regenText {
    display: block;
}

.disText {
    display: block;
}

.shareText {
    display: block;
}


.payment-history-table{
    margin-top: 1rem;
}

.payment-history-table-head tr td{
    padding: 10px;
} 

.payment-history-table th, 
.payment-history-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.payment-history-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}


.payment-table-transaction-cell{
    max-width: "180px"; 
    overflow-x: "auto"; 
    white-space: "nowrap";
}



@media screen and (max-width: 1460px) {
    .single-deliverable-tab {
        width: 55%;
    }
}

@media only screen and (max-width: 1200px) {
    .accordion {
        margin: 100px auto;
    }

    .dropAlbum {
        margin-left: 90%;
    }

    .deliverables {
        width: 95%;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }

    .regenText {
        display: block;
        text-overflow: ellipsis;
        max-width: 60px;
        overflow: hidden;
        white-space: nowrap;
    }

    .disText {
        display: block;
        text-overflow: ellipsis;
        max-width: 40px;
        overflow: hidden;
        white-space: nowrap;
    }

    .shareText {
        display: block;
        text-overflow: ellipsis;
        max-width: 40px;
        overflow: hidden;
        white-space: nowrap;
    }

    .deliverables {
        display: flex;
        justify-content: space-evenly;
        overflow-x: scroll;
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .copy-link {
        display: none !important;
    }


    .deliverables {
        width: 95%;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }

    .hollow-btn {
        font-size: 0.7rem;
    }

    .regenText {
        display: block;
        text-overflow: ellipsis;
        max-width: 30px;
        overflow: hidden;
        white-space: nowrap;
    }

    .disText {
        display: block;
        text-overflow: ellipsis;
        max-width: 20px;
        overflow: hidden;
        white-space: nowrap;
    }

    .shareText {
        display: block;
        text-overflow: ellipsis;
        max-width: 20px;
        overflow: hidden;
        white-space: nowrap;
    }

    .single-deliverable-tab {
        width: 55%;
        font-size: 8px;
    }

    .single-deliverable-tab button {
        font-size: 15px;
    }

    .single-deliverable-tab a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {

    .regenText {
        display: none;
    }

    .table-head th {
        font-size: 0.8rem;
    }

    .table-head td {
        font-size: 0.8rem;
    }


    .disText {
        display: none;
    }

    .shareText {
        display: none;
    }

    .shared-link-text {
        width: 200px;
        font-size: 0.8rem;
    }

    .deliverables {
        width: 90%;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }

    .booking-detail-panel {
        width: 100%;
    }

    .shared-list {
        width: 100%;
    }
}

@media screen and (max-width:720px) {
    .shared-link-text {
        width: 180px;
    }

    .shared-list li {
        gap: 4%;
    }
}



@media only screen and (max-width: 699px) {
    .booking-details {
        width: 90%;
        margin: 20px auto;
    }

    .booking-id {
        width: 100%;
    }


    .accordion-heading {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-direction: column-reverse;
        align-items: center;
        font-weight: 500;
        font-size: 20px;
    }

    .shared-link {
        gap: 10px;
    }

    .shared-list li {
        list-style: none;
        border-bottom: 1px solid #80808036;
        padding-bottom: 18px;
        padding-top: 18px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-evenly;
    }

    .shared-list li button {
        border-radius: 0.3rem;
        padding: 8px 10px;
        gap: 3px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 14px;
    }

    .hollow-btn {
        border: 2px solid #319795;
        color: #319795;
        background: transparent;
        width: -webkit-fill-available !important;
        border-radius: 0.3rem;
        padding: 6px 12px;
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .shared-link-text {
        font-size: 14px;
    }

    .share-everywhere {
        background: #319795;
        border-radius: 0.3rem;
        padding: 8px 13px;
        gap: 10px;
        display: flex;
        align-items: center;
        color: white;
        font-size: 16px;
    }

    .booking-detail-header h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .details-section fieldset {
        padding: 0px;
        border-radius: 4px;
        margin: 10px;
        border: 1px solid #c0c0c0;
        /* font-size: 17px; */
    }

    .table-head th {
        font-size: 15px !important;
    }

    .payRes td {
        font-size: 17px !important;
        padding: 3px;
    }

    .chakra-badge {
        padding: 3px !important;
    }
}

@media only screen and (max-width: 699px) {
    .deliverables {
        display: flex;
        flex-direction: row;
    }

    .single-deliverable {
        display: flex;
        flex-direction: row;
        padding: 15px 0;
        justify-content: center;
        width: 100%;
        align-items: center;
    }

    .single-deliverable-main {
        width: 100%;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        flex-direction: column;
    }

    .single-deliverable-tab {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        gap: 17px;
    }

    .pay-btn {
        width: 130px;
        font-size: 16px;
        text-align: center;
        justify-content: center;
    }

    .booking-detail-tab {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        gap: 17px;
        /* flex-direction: column; */
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid rgba(32, 32, 32, 0.121);
        border-bottom: 1px solid rgba(32, 32, 32, 0.121);
    }

    /* .tab-btn{
        width: 60%;
        text-align: center;
        justify-content: center;
    } */
    .tab-btn {
        width: 60%;
        text-align: center;
        justify-content: center;
        font-size: 14px;
        width: 165px;
    }

    .chakra-accordion__button {
        position: relative;
    }

    .chakra-accordion__button .filled-btn {
        background: #319795;
        color: white;
        border-radius: 0.3rem;
        padding: 8px 10px;
        gap: 5px;
        font-size: 14px;
        margin-left: -55%;

    }

    .css-1c1hh5a {
        margin-left: 0 !important;

    }

    .chakra-icon .chakra-accordion__icon .css-j2ph2z {
        margin-top: -38px !important;
        margin-left: 280px !important;
    }



    .accordion-heading {
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .chakra-accordion__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px 0px !important;
    }

    .deliverables {
        width: 100%;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        overflow-x: scroll;
        scrollbar-color: transparent transparent;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

    }

    .chakra-card {
        /* width: 90%; */
        margin: auto;
    }

    .accordion {
        width: 95%;
        margin: 100px auto;
    }

    .card-text-overlay {
        font-size: 26px;
        /* z-index: 1; */
    }

    .balance {
        display: flex;
        width: 100%;
        /* flex-direction: row; */
        align-items: center;
        justify-content: start;
        padding: 13px 0;
        flex-direction: column;
        gap: 9px;
        font-size: 15px;
    }

    .no-bookings {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
    }

    .eventgraphia-data {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid rgba(0, 0, 0, 0.238);
        justify-content: space-around;
    }

    .box-data span {
        font-size: 60px;
    }

    .box-data p {
        width: 100%;
        font-size: 16px;
        text-align: center;
    }


    .box-data {
        border: 1px solid rgba(0, 0, 0, 0.238);
        width: 50%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .no-booking-header h2 {
        font-size: 24px;
        font-weight: 700;
    }

    .callback-section {
        font-size: 20px;
        text-align: center;
    }

    .callback-section button {
        font-size: 18px;
    }

    .chakra-accordion__icon {
        margin-top: -38px !important;
        z-index: 10;
        margin-left: 85% !important;
    }
}

@media only screen and (min-width:699px) and (max-width: 800px) {
    .deliverables {
        width: 95%;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }


    .card-text-overlay {
        font-size: 26px;
        /* z-index: 1; */
    }

    .booking-detail-tab {
        display: flex;
        flex-direction: column;
    }

    .hollow-btn {
        display: flex;
        justify-content: center;
        width: -webkit-fill-available;
        margin: 10px 0;
    }
}

@media screen and (max-width: 600px) {
    .shared-link-text {
        width: 148px;
    }

    .multipleEst {
        font-size: 18px;
        display: flex;
        width: fit-content;
        overflow: scroll;
        scrollbar-color: transparent transparent;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
    }

    .multipleEst a {
        touch-action: pan-x;
    }

    .details-section {
        font-size: 15px;
    }


    .payment-history-table-head th {
        font-size: 12px !important;
        padding: 8px 6px !important;
    }

    .payRes td {
        font-size: 10px !important;
        padding: 2px;
    }

    .chakra-badge {
        padding: 3px !important;
    }
}

@media screen and (max-width: 500px) {
    .details-section fieldset table tbody tr th {
        font-size: 15px !important;
    }

    .details-section fieldset table tbody tr td {
        font-size: 15px !important;
    }

    /* .table-head th {
        font-size: 15px !important;
    }

    .table-head tr td {
        font-size: 10px !important; */
}